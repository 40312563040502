<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Cập nhật vùng kinh tế
        </CCardHeader>
        <CCardBody style="min-height: 300px">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên vùng kinh tế" horizontal :value.sync="detailItem.ten"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CInput label="Mã vùng kinh tế" horizontal :value.sync="detailItem.ma"/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked.sync="detailItem.trangThai"/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isLoading"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { GET_VUNGKINHTE, UPDATE_VUNGKINHTE } from '@/store/modules/vung-kinh-te/actionTypes'

export default {
  name: 'VungKinhTeUpdate',
  data () {
    return {
      optionsTrangThai: statuses,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('vungKinhTe', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('vungKinhTe', {
      getItem: GET_VUNGKINHTE,
      updateItem: UPDATE_VUNGKINHTE
    }),
    async saveItem () {
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/vung-kinh-te' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/vung-kinh-te' })
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getItem(this.$route.params.id)
    this.isLoading = false
  }
}
</script>
